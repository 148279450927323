<template>
  <div class="body" v-if="planConfigInfo.id">
    <b-tabs horizontal nav-wrapper-class="nav-bottom">
      <b-tab active title="Plan General Info">
        <b-row class="d-flex justify-content-between header mb-2 mt-3">
          <b-col>
            <h1 class="serverName text-primary ml-2">
              {{
                `${planConfigInfo.name.toUpperCase()}`
              }}
            </h1>
          </b-col>
        </b-row>
        <b-row class="ml-2">
          {{ `Created at: ${planConfigInfo.created_at}` }}
        </b-row>

        <hr class="my-2" />
        <b-col class="ml-1" cols="12">
          <b-row class="mt-2">
            <b-col class="mb-1" cols="5">
              <b-form-group
                label="Config Name"
                label-for="plan-name"
                label-cols-md="4"
              >
                <b-input-group
                  v-if="planConfigInfo.name"
                  class="input-group-merge"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon 
                      class="text-primary"
                      icon="BoxIcon" 
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    id="plan-name"
                    placeholder="Config Name"
                    :value="`  ${planConfigInfo.name}`"
                    readonly
                  />
                  <b-input-group-append is-text>
                    <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editPlanConfig('Name')">
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <div v-else>
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </b-form-group>
            </b-col>

            <b-col class="mb-1 ml-2" cols="4">
              <b-form-group
                label="Main Config"
                label-for="plan-main-config"
                label-cols-md="4"
              >
                <b-form-checkbox
                  id="plan-main-config"
                  :class="`custom-control-success`"
                  class="mt-1"
                  @change="editIsMainConfig()"
                  v-model="planConfigInfo.main_config"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" class="text-light" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" class="text-danger" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="mb-1" cols="5">
              <b-form-group
                label="Status"
                label-for="server-status"
                label-cols-md="4"
              >
                <b-input-group v-if="planConfigInfo" class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PowerIcon" class="text-primary"/>
                  </b-input-group-prepend>
                  <b-form-input
                    id="server-status"
                    placeholder="Plan Config Status"
                    readonly
                    :value="`  ${planConfigInfo.active}`"
                  />
                  <b-input-group-append is-text>
                    <b-button
                      variant="ml-1 btn btn-warning text-white py-25 px-75"
                      @click="editPlanConfig('Active')"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <div v-else>
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <hr class="my-1" />
        <b-row>
          <h2 class="mt-1 mb-3 ml-3 text-primary">Metrics</h2>
        </b-row>
        <b-col class="ml-1">
          <b-row
            v-for="item in planConfigInfo.metrics"
            :key="item.enum_metric.id"
          >
            <b-col class="mb-1" cols="4">
              <b-form-group
                :label="item.enum_metric.label"
                :label-for="`${item.enum_metric.label}-id`"
                label-cols-md="5"
              >
                <b-input-group v-if="planConfigInfo" class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon 
                      icon="CheckIcon" 
                      class="text-primary"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    :id="`${item.enum_metric.label}-id`"
                    readonly
                    :value="`${item.value}`"
                  />
                  <b-input-group-append is-text>
                    <b-button
                      variant="ml-1 btn btn-warning text-white py-25 px-75"
                      @click="editPlanConfigMetrics(item.enum_metric)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <div v-else>
                  <b-skeleton type="input"></b-skeleton>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-tab>
    </b-tabs>

    <base-modal />
    <hr class="my-2" />
  </div>
</template>

<script>
import BaseModal from "@/layouts/components/BaseModal.vue";
import custom from "@/custom";
import { makeToast } from "@/layouts/components/Popups";

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BInputGroupAppend,
    BFormTextarea,
    BaseModal,
    BSkeleton,
    BTabs,
    BTab,
    BCardText,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      planConfigInfo: [],
    };
  },

  created() {
    this.$root.$refs[this.$route.meta.pageTitle] = this;
  },

  mounted() {
    this.init();
  },

  methods: {
    init(){
      this.fetchPlanConfigById();
    },
    fetchPlanConfigById() {
      this.$store
        .dispatch("getPlanConfigById", this.$route.params)
        .then((response) => {
          console.log(response, 'fetchPlanConfigById');
          this.$store.commit("SET_CURRENT_PLAN_CONFIG", response);
          this.planConfigInfo = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editPlanConfig(action) {
      this.$store.dispatch("setPlanConfigAction", action).then(() => {
        this.$store
          .dispatch("setModalObj", `editPlanConfig${action}`)
          .then(() => {
            this.$store.dispatch("setActiveModal", true);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    editPlanConfigMetrics(data) {
      
      let action = data.label;

      this.$store.dispatch("setCurrentPlanConfigMetrics", data.id).then(() => {
        this.$store.dispatch("setPlanConfigMetricsAction", action).then(() => {
          this.$store
          .dispatch("setModalObj", `editPlanConfigMetrics${action}`)
          .then(() => {
            this.$store.dispatch("setActiveModal", true);
          })
          .catch((error) => {
            console.log(error);
          });
        });
      })
    },
    editIsMainConfig() {
      let action = 'togglePlanIsMainConfig'
      let modalData = custom.confirmModalStructure.togglePlanIsMainConfig;
      this.$bvModal
        .msgBoxConfirm(modalData.message + " ?", modalData.config)
        .then((value) => {
          if (value) {
            this.$store.dispatch("setLoadingState", true);
            this.$store
              .dispatch(action, this.planConfigInfo.main_config)
              .then(() => {
                // this.$store.dispatch(this.$route.meta.renderFuntion, this.$route.params || null).then(response => {
                this.$store.dispatch("setLoadingState", false);

                makeToast(custom.successMessages[action]);
                // })
              })
              .catch((error) => {
                this.$store.dispatch("setLoadingState", false);
                this.fetchPlanConfigById();

                makeToast(
                  custom.errorMessages[error.code ? error.code : action]
                );
              });
          } else {
            this.fetchPlanConfigById();
            makeToast(custom.warningMessages[action]);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>